import { createApp } from 'vue'
import { createPinia } from 'pinia'
import router from './router'
import App from './App.vue'
import InstagramFeed from "vue3-instagram-feed"
import "vue3-instagram-feed/dist/style.css"

const pinia = createPinia()
const app = createApp(App)
app.use(router).use(pinia).use(InstagramFeed)

router.isReady().then(() => app.mount('#app'))
